/**
 * @author Maxime Mustarda <maxime@inarix.com>
 * @file App.css
 * @desc Created on Tue May 24 2022 19:04:21
 * @copyright All rights reserved @ Inarix
 */
* {
  font-family: 'Inter', sans-serif;
}
body {
  margin: 0;
}
.pointer, button, a {
  cursor: pointer;
}
.clear {
  width: 0 !important;
  height: 0 !important;
  margin: 0 !important;
  padding: 0 !important;
  clear: both;
}
.left {
  float: left;
}
.right {
  float: right;
}
.flex {
  display: flex;
}

/* Spinner credits: https://github.com/loadingio/css-spinner/ */
.spinner {
  width: 40px;
  height: 40px;
  margin: 0 auto !important;
}
.spinner:after {
  content: " ";
  display: block;
  width: 32px;
  height: 32px;
  margin: 4px;
  border-radius: 50%;
  border: 3px solid #C0C0C0; /* picked from theme.palette.text */
  border-color: #C0C0C0 transparent #C0C0C0 transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

#login {
  background-image: url('../public/grains-bg.jpg');
  background-repeat: none;
  background-position: center;
  background-size: cover;
  width: 100vw;
  height: 100vh;
}
#top_right {
  position: absolute;
  top: 5px;
  right: 15px;
  z-index: 10;
}
#top_right>* {
  margin-left: 20px;
}

/* modals */
.mod_content {
  position: relative;
  top: 50%;
  transform: translate(0, -50%);
  max-width: 50%;
  max-height: 50%;
  margin: auto;
}
.mod_content:focus {
  outline: none;
}
.mod_content>canvas {
  display: block;
  margin: auto;
}
.mod_content>img {
  visibility: hidden;
  pointer-events: none;
  max-width: 50%;
  max-height: 50%;
}

#sidebar>div::-webkit-scrollbar {
  display: none;
}
#sidebar>div::-webkit-scrollbar:horizontal {
  display:block;
  height: 10px;
}
#sidebar>div::-webkit-scrollbar-thumb:horizontal {
  background-color: #5D5D5D;
  border-radius: 10px;
}
#sidebar>div::-webkit-scrollbar-track:horizontal {
  background-color: #464646;
}
.job_list .MuiTypography-root {
  /* overflow-x: hidden; */
  /* text-overflow: ellipsis; */
  white-space: nowrap;
}

/* job page */
#header {
  line-height: 24px;
  vertical-align: middle;
}
#header>* {
  margin: 15px 0;
}
#body {
  margin-top: 5px;
  margin-bottom: 5px;
}
#bottom {
  margin-top: 15px;
}
#body>div {
  max-height: 680px;
}

#img_list_container {
  min-height: 664px;
}
#img_list {
  flex-wrap: wrap;
  width: 850px;
  max-height: inherit;
  overflow-y: auto;
}
#img_list::-webkit-scrollbar {
  display: none
}
#img_list.small {
  width: 166px;
}
#img_list>div {
  cursor: pointer;
  width: 150px;
  height: 150px;
  margin: 8px 16px 8px 0;
  border: 2px solid transparent;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
#img_list>div.labelled {
  opacity: .3;
}
#img_list>div.selected {
  opacity: 1;
  border-color: #C595D4; /* picked from theme.palette.secondary.main */
}
#img_list>div:hover {
  opacity: 1;
}
#img_list .label_dot {
  width: 10px;
  height: 10px;
  margin: 10px;
  border: 2px solid #211D26;
  border-radius: 7px;
}

#big_image {
  width: 450px;
  margin: 10px 119px 10px 115px;
  flex-shrink: 0;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

#image_props {
  margin: 10px 0 10px 10px;
  width: 300px;
  flex-shrink: 0;
  text-align: left;
}
.panel_head {
  padding: 10px;
}

/* !importants because of MUI */
#label_btns {
  max-width: 1000px;
}
#existing_labels>span {
  display: inline-block;
}
.label_btn {
  border-radius: 20px !important;
  margin-right: 10px !important;
  margin-bottom: 10px !important;
  text-transform: capitalize !important;
  border: 1px solid !important;
  padding: 5px 10px !important;
}
.label_btn_freetext {
  margin-left: 0 !important;
  margin-right: 0 !important;
  border: none !important;
  min-width: 0 !important;
}
/* hacky :( */
.label_btn_freetext>.MuiButton-startIcon {
  margin: 0;
}
.shortcut_btn {
  display: inline-block;
  font-size: .9em !important;
  width: 25px;
  height: 25px;
  line-height: 25px;
  border-radius: 15px;
}
.shortcut_txt {
  color: white;
  text-shadow:black 0 0 3px;
  /* mix-blend-mode: difference; */
  /* -webkit-text-stroke: .2px black; */
}
